<template>
  <div class="container detail" v-loading="loading">
    <div class="base">
      <section class="contents">
        <article class="inner mainImg">
          <img :src="`${thumbnail}`" alt="item" />
        </article>
      </section>
      <section class="fundingInfo">
        <p>
          <span>{{ limitType == "PERIOD" ? "설문 일정" : "설문 시작" }}</span>
          {{ moment(startAt).format("YYYY년 MM월 DD일") }}
          {{
            limitType == "PERIOD"
              ? moment(endAt).format("~ YYYY년 MM월 DD일")
              : ""
          }}
        </p>
        <h2>{{ title }}</h2>
        <h3>
          <em>현재가격</em>
          <span>{{ numberFormat(avgAmount) }}</span
          >원
        </h3>
        <ul>
          <li>
            시작가격
            <span>{{ numberFormat(startAmount) }}원</span>
          </li>
          <li>
            {{
              limitType == "PERIOD"
                ? "설문마감"
                : limitType == "COUNT"
                ? "제한인원"
                : ""
            }}
            <!-- 설문마감 인원 or 시간 -->
            <span v-if="status == 'FINISH'">설문마감</span>
            <span v-else-if="limitType == 'PERIOD' && dday > 0" class="time"
              >D-{{ dday }}</span
            >
            <span v-else-if="limitType == 'PERIOD' && dday == 0" class="time">
              <countdown :time="endtime" v-if="dday == 0">
                <template slot-scope="props"
                  >{{ props.hours }}시간 {{ props.minutes }}분
                  {{ props.seconds }}초 남음</template
                >
              </countdown>
            </span>
            <span v-else-if="limitType == 'COUNT'" class="number"
              >{{ numberFormat(userCount) }}명</span
            >
          </li>
          <li v-if="status == ''">
            참여인원
            <span>{{ numberFormat(surveyers) }}명</span>
          </li>
        </ul>
        <div class="coupon">
          <p>* {{ couponInfo }}</p>
        </div>
        <router-link to @click.native="handleMove()" class="point">{{
          status == "FINISH" ? "참여내역" : "참여하기"
        }}</router-link>
        <div class="buttonWrap">
          <button @click="handleContactModal">
            <img src="@/assets/image/icn-help.svg" alt="help" />
            <span>문의</span>
          </button>
          <button @click="handleUrlCopy">
            <img src="@/assets/image/icn-share.svg" alt="share" />
            <span>URL 복사</span>
          </button>
        </div>
      </section>
      <section class="contents">
        <article class="inner">
          <div class="tabs">
            <span @click="handleChangeTab()">제품 상세</span>
            <span @click="handleChangeTab()">인플루언서 리뷰</span>
            <span @click="handleChangeTab()">참여자 의견</span>
            <span @click="handleChangeTab()">선정 결과</span>
          </div>
          <div class="tab tab1">
            <h4>제품 상세</h4>
            <div>
              <img v-for="(data, i) in images" :key="i" :src="`${data}`" alt />
              <pre>{{ contents }}</pre>
            </div>
          </div>
          <div class="tab tab2">
            <h4>인플루언서 리뷰</h4>

            <div>
              <ul>
                <li>
                  <div v-if="youtubeThumbnail">
                    <img :src="`${youtubeThumbnail}`" alt />
                  </div>
                  <div class="video-container" v-if="youtubUrl">
                    <div v-html="youtubUrl"></div>
                  </div>
                  <div v-if="youtubUrl">
                    <a :href="`${youtubUrl}`" class="flr point" target="_blank"
                      >유튜브로 이동</a
                    >
                  </div>
                  <div
                    v-if="
                      youtubeThumbnail == undefined &&
                      youtubUrl == '' &&
                      influencerContent == ''
                    "
                    class="nodata"
                  >
                    해당 설문은 인플루언서 리뷰가 없습니다.
                  </div>
                  <pre class="youtubeCont">{{ influencerContent }}</pre>
                </li>
              </ul>
            </div>
          </div>
          <div class="tab tab3">
            <h4>참여자 의견</h4>

            <div v-if="surveyList.length > 0">
              <ul>
                <li v-for="(data, i) in surveyList" :key="i">
                  <span>{{
                    data.user ? emailHide(data.user.email) : null
                  }}</span>
                  <span>{{ moment(data.createdAt).format("YYYY.MM.DD") }}</span>
                  <p>
                    <span class="material-icons">sell</span>
                    {{ numberFormat(data.amount) }}원
                  </p>
                  <pre
                    class="blind"
                    v-if="data.isBlind"
                  ><em class="material-icons">
error_outline
</em> 관리자에 의해 차단된 리뷰 입니다.</pre>
                  <pre v-else>{{ data.comment }}</pre>
                </li>
              </ul>
              <div class="pagination">
                <el-pagination
                  layout="prev, pager, next"
                  :total="total"
                  :page-size="6"
                  @current-change="handleCurrentChange"
                ></el-pagination>
              </div>
            </div>
            <div v-else class="nodata">참여자 코멘트가 없습니다.</div>
          </div>
          <div class="tab tab4">
            <h4>선정 결과</h4>
            <div>
              <p>{{ couponInfo }} ({{ winCount }}명)</p>
              <ul v-if="winners.length > 0">
                <li v-for="(data, i) in winners" :key="i">
                  <span>&#127881;</span>
                  <!-- <span>{{ data.username }}</span> -->
                  <span>{{ nameNoHide(data.username) }}</span>
                  <span>{{ mobileNoHide(data.mobileNo) }}</span>
                </li>
              </ul>
              <div v-if="status != 'FINISH'" class="nodata">
                현재 설문이 진행중입니다.
              </div>

              <div class="img" v-show="resultImages.length > 0">
                <!-- <img src="@/assets/images/graph.png" alt="" /> -->
                <ul>
                  <li v-for="(image, i) in resultImages" :key="i">
                    <img :src="image" alt />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </article>
      </section>
    </div>
    <transition name="fade">
      <div class="dim" v-if="modalVisible">
        <ContactModal v-if="contactVisible" @close="close" />

        <div class="dimbg" @click="close"></div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchPostDetail, fetchSurveyList } from "@/api/post";
import { format } from "@/mixins/format";
import ContactModal from "@/components/pop/ContactModal";

export default {
  mixins: [format],
  components: { ContactModal },

  data() {
    return {
      moment: moment,
      modalVisible: false,
      contactVisible: false,
      fundingVisible: false,
      postId: "",
      startAt: "",
      title: "",
      avgAmount: "",
      startAmount: "",
      surveyers: "",
      endAt: "",
      limitType: "",
      userCount: "",
      couponInfo: "",
      thumbnail: "",
      images: [],
      dday: 0,
      winCount: 0,
      youtubeThumbnail: "",
      youtubUrl: "",
      total: 0,
      surveyCount: 0,
      currentPage: 1,
      surveyList: [],
      status: "",
      winners: [],
      resultImages: [],
      loading: false,
      endtime: "",
      contents: "",
      influencerContent: "",
    };
  },
  computed: {
    ...mapState(["userId", "isLogin"]),
  },
  mounted() {
    this.loading = true;
    if (this.$route.params.id) {
      this.postId = this.$route.params.id;
      this.getPost();
      this.getSurvey();
    }
  },
  // updated() {
  //   this.handleChangeTab();
  // },
  methods: {
    getPost() {
      fetchPostDetail(this.postId).then((res) => {
        if (res.data.status == 200) {
          this.startAt = res.data.data.startAt;
          this.title = res.data.data.title;
          this.avgAmount = Math.round(res.data.data.avgAmount);
          this.startAmount = res.data.data.startAmount;
          this.surveyers = res.data.data.surveyers.length;
          this.endAt = res.data.data.endAt;
          this.limitType = res.data.data.limitType;
          this.userCount = res.data.data.userCount;
          this.couponInfo = res.data.data.couponInfo;
          this.winCount = res.data.data.winCount;
          this.thumbnail = res.data.data.thumbnail;
          this.images = res.data.data.images;
          this.contents = res.data.data.contents;
          this.youtubUrl = res.data.data.youtubUrl;
          this.youtubeThumbnail = res.data.data.youtubeThumbnail[0];
          this.influencerContent = res.data.data.influencerContent;
          this.winners = res.data.data.winners;
          this.resultImages = res.data.data.resultImages;
          this.endtime = new Date(this.endAt).getTime() - new Date().getTime();
          this.dday =
            Math.ceil(
              (new Date(this.endAt).getTime() - new Date().getTime()) /
                (1000 * 60 * 60 * 24)
            ) - 1;
          this.status =
            (this.limitType == "COUNT" && this.surveyers >= this.userCount) ||
            (this.limitType == "PERIOD" && this.dday < 0) ||
            res.data.data.status == "FINISH"
              ? "FINISH"
              : "";
          this.loading = false;
        } else {
          return alert(res.data.message);
        }
      });
    },
    getSurvey() {
      let params = {
        page: this.currentPage,
      };
      fetchSurveyList(this.postId, params).then((res) => {
        if (res.data.status == 200) {
          this.surveyList = res.data.data.surveylist;
          this.total = res.data.data.total;
          this.currentPage = res.data.data.page;
        } else {
          return alert(res.data.message);
        }
        this.handleChangeTab();
      });
    },
    handleMove() {
      if (!this.isLogin) {
        alert("로그인 후 이용해주세요.");
        this.$router.push("/login");
      } else {
        this.$router.push({
          name: "surveyHistory",
          params: { id: this.postId },
        });
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSurvey();
    },
    handleChangeTab() {
      const spans = document.querySelectorAll(".tabs span");
      const tabs = document.querySelectorAll(".tab");
      const firstTop = tabs[0].offsetTop;
      const secondTop = tabs[1].offsetTop;
      const thirdTop = tabs[2].offsetTop;
      const fourthTop = tabs[3].offsetTop;

      spans[0].onclick = function () {
        window.scroll({ top: firstTop, behavior: "smooth" });
      };
      spans[1].onclick = function () {
        window.scroll({ top: secondTop, behavior: "smooth" });
      };
      spans[2].onclick = function () {
        window.scroll({ top: thirdTop, behavior: "smooth" });
      };
      spans[3].onclick = function () {
        window.scroll({ top: fourthTop, behavior: "smooth" });
      };
    },
    close() {
      this.modalVisible = false;
      this.contactVisible = false;
      this.fundingVisible = false;
    },
    handleContactModal() {
      this.modalVisible = true;
      this.contactVisible = true;
    },
    handleUrlCopy() {
      var dummy = document.createElement("input");
      var text = location.href;

      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      this.$alert("원하는 곳에 붙여넣기 해주세요.", "URL이 복사되었습니다.", {
        confirmButtonText: "OK",
        center: true,
      });
    },
  },
};
</script>
