<template>
  <div class="dimbox contact">
    <div class="mBody">
      <div class="flexB">
        <img src="@/assets/images/logo.png" alt="call" />
        <div>
          <p>
            전화
            <a :href="`tel:${CALL_CENTER}`">{{ CALL_CENTER }} </a>
          </p>
          <p>
            이메일
            <a href="mailto:ulmani5955@gmail.com"> ulmani5955@gmail.com</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CALL_CENTER } from "@/config/env";

export default {
  data() {
    return {
      CALL_CENTER: CALL_CENTER,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
